<template>
  <div>
    <div class="text-center mt-6" v-if="!dataLoaded">
      <v-progress-circular :size="50" color="primary" indeterminate />
    </div>

    <div v-else>
      <v-row class="my-0">
        <v-col cols="12" class="pt-0">
          <v-card class="base-card pa-5">
            <v-btn
              :disabled="!cycle"
              :loading="loading"
              small
              color="primary"
              class="white--text px-4 mb-5"
              @click="sendToAx"
            >
              <v-icon left size="18">mdi-account-arrow-down-outline</v-icon>Выгрузить в учебное управление
            </v-btn>

            <div class="inputs">
              <v-autocomplete
                v-model="department"
                :loading="departmentLoading"
                class="mt-0"
                label="Кафедра"
                placeholder="Выберите значение из списка"
                :items="departmentsList"
                item-text="name"
                hide-no-data
                :hide-details="!cyclesList.length && !isShowYear"
                hide-selected
                return-object
                clearable
                @change="getCycles"
                @click:clear="resetDepartment"
              />

              <v-autocomplete
                v-if="isShowYear"
                v-model="year"
                :loading="yearLoading"
                label="Год"
                placeholder="Выберите значение из списка"
                :items="yearsList"
                hide-no-data
                :hide-details="!cyclesList.length"
                clearable
                @change="getCycles"
                @click:clear="resetYear"
              />

              <v-autocomplete
                v-show="(department || year) && cyclesList.length"
                v-model="cycle"
                label="Номер цикла"
                placeholder="Выберите значение из списка"
                :items="cyclesList"
                item-text="cycleNum"
                hide-no-data
                hide-selected
                hide-details
                return-object
                clearable
              />
            </div>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import { departmentApi, integrationApi } from '@/api'
import { mapGetters } from 'vuex'

export default {
  name: 'UploadingListeners',

  metaInfo: {
    title: 'Выгрузка слушателей'
  },

  async created() {
    await this.getDepartments()
    this.dataLoaded = true
  },

  mounted() {
    this.hideHtmlOverflow()
  },

  destroyed() {
    this.showHtmlOverflow()
  },

  data: () => ({
    dataLoaded: false,
    loading: false,

    departmentLoading: false,
    department: null,
    departmentsList: [],

    yearLoading: false,
    year: null,

    cycle: null,
    cyclesList: []
  }),

  computed: {
    ...mapGetters('user', ['isSuperAdmin']),

    isShowYear() {
      return this.isSuperAdmin
    },

    yearsList() {
      const startYear = 2006, yearNow = new Date().getFullYear(), yearsList = []

      if (typeof yearNow === 'number' && yearNow > startYear) {
        for (let i = startYear; i <= yearNow; i++) {
          yearsList.push(i)
        }
      }

      return yearsList
    }
  },

  methods: {
    async getDepartments() {
      try {
        this.departmentsList = await departmentApi.getDepartmentsList()
      } catch (e) {
        console.log(e.status)
      }
    },

    async getCycles() {
      this.resetParams()

      if (this.department && this.year) {
        await this.getDepartmentAndYearCycles()
      } else if (this.department) {
        await this.getDepartmentCycles()
      } else if (this.year) {
        await this.getYearCycles()
      }
    },

    async getDepartmentCycles() {
      try {
        this.departmentLoading = true

        const cyclesList = this.isShowYear
          ? await departmentApi.getCyclesAllTimeByDepartmentId(this.department.id)
          : await departmentApi.getCyclesByDepartmentId(this.department.id)

        if (this.isShowYear) {
          this.cyclesList = cyclesList.map(cycle => {
            return {
              ...cycle,
              cycleNum: `${cycle.cycleNum} (${cycle.academyYearId})`
            }
          })
        } else {
          this.cyclesList = cyclesList
        }
      } catch (e) {
        console.log(e.status)
      } finally {
        this.departmentLoading = false
      }
    },

    async getYearCycles() {
      try {
        this.yearLoading = true

        this.cyclesList = await departmentApi.getCyclesByYear(this.year)
      } catch (e) {
        console.log(e.status)
      } finally {
        this.yearLoading = false
      }
    },

    async getDepartmentAndYearCycles() {
      try {
        this.departmentLoading = true
        this.yearLoading = true

        const cyclesList = await departmentApi.getCyclesAllTimeByDepartmentId(this.department.id)
        this.cyclesList = cyclesList.filter(cycle => +cycle.academyYearId === this.year)

        if (!this.cyclesList.length) {
          this.$toastr('warning', '', 'Циклы не найдены')
        }
      } catch (e) {
        console.log(e.status)
      } finally {
        this.departmentLoading = false
        this.yearLoading = false
      }
    },

    async sendToAx() {
      try {
        if (this.cycle) {
          this.loading = true

          await integrationApi.sendToAxAdmin(this.cycle.cycleId)
          this.$toastr('success', '', 'Выгружено в учебное управление')

          this.resetParams()
        }
      } catch (e) {
        this.showErrorMessage(e, 'Ошибка выгрузки в учебное управление')
      } finally {
        this.loading = false
      }
    },

    resetDepartment() {
      this.department = null
      this.resetParams()
    },

    resetYear() {
      this.year = null
      this.resetParams()
    },

    resetParams() {
      this.cycle = null
      this.cyclesList = []
    },

    hideHtmlOverflow() {
      document.querySelector('html').style.setProperty('overflow-y', 'hidden', 'important')
    },

    showHtmlOverflow() {
      document.querySelector('html').style.overflowY = 'visible'
    }
  }
}
</script>

<style scoped>
.base-card .inputs {
  max-width: 600px;
}
</style>